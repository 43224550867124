export function formatSeconds(seconds = 0) {
  var date = new Date(0);
  date.setSeconds(seconds);

  var time =
    date.getUTCMinutes() +
    ':' +
    date.getUTCSeconds().toString().padStart(2, '0');

  return time;
}
