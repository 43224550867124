import React, { FC, useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useProviderState } from './StateProvider';

/**
 * The Chapter Preview thumbnail component for Desktop
 */
export const ChapterPreview: FC<{ show: boolean }> = ({ show }) => {
  const [{ chapters, preview, videoElement: video, segment }] =
    useProviderState();
  const [duration, setDuration] = useState(0);
  const showTitleText = false; // in other projects we might want to

  useEffect(() => {
    if (!duration && video) {
      setDuration(video.duration);
    }
  }, [duration, video]);

  if (!segment && !preview) {
    return null;
  }

  /*
    When the timeline segment is active/hovered on by the
    user we want to display the chapter preview thumbnail and title.
  */
  const chapterId = segment || preview;
  const chapter = chapters.find(chap => chap.chapter === chapterId)!; // assert that this will never be null
  const justify = duration / 2 <= chapter.startTime ? 'start' : 'end';

  return (
    <Container
      className="tw-reset"
      role="presentation"
      show={show}
      justify={justify}
    >
      <img src={chapter.image.url} alt={chapter.title} />
      <SkipText>Skip to chapter {chapter.chapter}</SkipText>
      {showTitleText && <TitleText>{chapter.title}</TitleText>}
    </Container>
  );
};
const Container = styled.div<{ justify: string; show: boolean }>(
  ({ justify, show }) => [
    tw`
pointer-events-none grid-row-start[2] grid-row-end[3] grid-column-start[2] grid-column-end[3]
z-20 text-brand-blue-dark w-full max-width[280px]`,
    css`
      justify-self: ${justify};
    `,
    !show && tw`opacity-0`,
  ],
);

const SkipText = tw.p`uppercase! font-bold! text-sm! leading-none! tracking-wider! mb-1.5! mt-4!`;
const TitleText = tw.p`mt-0! pt-0! font-size[18px]! font-normal!`;
