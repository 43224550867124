import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import { VideoPlayer } from '../../components/VideoPlayer';
import { Chapters } from '../VideoPlayer/video-player-types';
import videoMp4 from '../../video/ccl.mp4';
import videoWebm from '../../video/ccl.webm';
import poster from '../../video/ccl.jpg';
import thumb1 from '../../images/ccl-1.png';
import thumb2 from '../../images/ccl-2.png';
import thumb3 from '../../images/ccl-3.png';
import thumb4 from '../../images/ccl-4.png';

const chapters: Chapters = [
  {
    chapter: 1,
    startTime: 0,
    duration: 538,
    title: 'UTUC Background and Basics',
    image: { url: thumb1, alt: 'Chapter 1: UTUC Background and Basics' },
  },
  {
    chapter: 2,
    startTime: 539,
    duration: 540,
    title: 'UTUC Diagnosis',
    image: { url: thumb2, alt: 'Chapter 2: UTUC Diagnosis' },
  },
  {
    chapter: 3,
    startTime: 1080,
    duration: 1138,
    title: 'UTUC Treatment Options',
    image: { url: thumb3, alt: 'Chapter 3: UTUC Treatment Options' },
  },
  {
    chapter: 4,
    startTime: 2219,
    duration: 1507,
    title: 'UTUC Resources and Questions & Answers',
    image: {
      url: thumb4,
      alt: 'Chapter 4: UTUC Resources and Questions & Answers',
    },
  },
];

export const CCLVideo: FC = () => {
  return (
    <Container>
      <VideoPlayer chapters={chapters} poster={poster}>
        <source src={videoMp4} />
        <source src={videoWebm} />
      </VideoPlayer>
    </Container>
  );
};

const Container = tw.div`mt-4`;
