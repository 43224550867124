import React, { FC } from 'react';
import ReactSlider from 'react-slider';
import tw, { css, styled } from 'twin.macro';
import { useProviderState } from './StateProvider';

export const SeekControl: FC<{ show: boolean }> = ({ show }) => {
  const [{ videoElement: video }] = useProviderState();
  if (!video) {
    console.error('vid null');
    return null;
  }

  const duration = isNaN(video.duration) ? 1 : video.duration;
  const value = (video.currentTime / duration) * 100;
  const onChange = (value, index: number) => {
    if (video) {
      video.currentTime = value === 0 ? 0 : (value / 100) * duration;
    }
  };

  return (
    <Container css={[!show && tw`opacity-0`]}>
      <InnerContainer>
        <ReactSlider
          value={value}
          onChange={onChange}
          renderThumb={(props, state) => (
            // @ts-ignore: "as" on <Thumb /> is reported as incompatible, but it is not
            <Thumb {...props} aria-label="seek">
              &#x2B22;
            </Thumb>
          )} // Unicode for hexigon shape
          withTracks={false}
          step={0.33333333}
        />
      </InnerContainer>
    </Container>
  );
};

const Container = tw.div`grid-area[3 / 2 / auto / 3]  relative`;
const InnerContainer = tw.div`absolute h-4 -bottom-2 w-full`;
const Thumb = styled.div(() => [
  tw`opacity-70 w-4 h-4 text-brand-blue cursor-pointer z-20! rotate-90 text-2xl line-height[1rem]`,
  css`
    &:hover,
    &:focus {
      opacity: 1;
    }
  `,
]);
