import React, { FC, useState } from 'react';
import tw from 'twin.macro';
import { PlayIconContainer, PlayToggleButton } from './MinimalControls';
import { useProviderState } from './StateProvider';
import { formatSeconds } from './utils';
import { Label } from './video-player-shared';

export const PlayControl: FC<{ show: boolean }> = ({ show }) => {
  const [{ playing, videoElement: video, currentTime }] = useProviderState();
  const [hover, setHover] = useState(false);

  return (
    <PlayControlContainer css={[!show && tw`opacity-0`]} role="presentation">
      <PlayToggleButton
        aria-label={playing ? 'pause' : 'play'}
        onClick={() => (playing ? video?.pause() : video?.play())}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        customCss={[
          tw`mt-4 bg-transparent! border-radius[0]! focus:(outline[2px dotted #4A387A])`,
        ]}
      >
        <PlayIconContainer role="presentation" css={[tw`bg-brand-white`]}>
          {playing ? <PauseIcon hover={hover} /> : <PlayIcon hover={hover} />}
        </PlayIconContainer>

        <Label tw="block ml-2 my-0! uppercase text-brand-blue-md text-base line-height[1rem] ">
          {formatSeconds(currentTime)}
        </Label>
      </PlayToggleButton>
    </PlayControlContainer>
  );
};

const PlayIcon: FC<{ hover: boolean }> = ({ hover }) => {
  return (
    <svg
      className="ts-reset"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={hover ? '#1671a7' : '#43798d'}
      viewBox="0 0 24 24"
      tw="m-0! w-6! h-6!"
    >
      <title>Play</title>
      <path d="M8 5v14l11-7z" />
    </svg>
  );
};

const PauseIcon: FC<{ hover: boolean }> = ({ hover }) => {
  return (
    <svg
      className="ts-reset"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 8 10"
      tw="m-0! width[12px]! height[14px]!"
    >
      <title>Pause</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill={hover ? '#1671a7' : '#43798d'}
          transform="translate(-698 -1155)"
        >
          <g transform="translate(681 1139)">
            <g transform="translate(17 16)">
              <path d="M2.17748047e-13 1.99650013L2.16012924 0.352095701 2.16012924 9.31700061 2.17748047e-13 9.31700061z" />
              <path d="M5.50000012 1.99650013L7.66012936 0.352095701 7.66012936 9.31700061 5.50000012 9.31700061z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const PlayControlContainer = tw.div`grid-row[3] col-start-2 col-end-3 z-10 pointer-events-none`;
