import React, { FC } from 'react';
import tw from 'twin.macro';
import { ChapterPreview } from './ChapterPreview';
import { ChapterRegions } from './ChapterRegions';
import { ExpandingControl } from './ExpandingControl';
import { FullscreenControl } from './FullscreenControl';
import { PlayControl } from './PlayControl';
import { SeekControl } from './SeekControl';
import { TimelineControls } from './TimelineControls';
import { VolumeControl } from './VolumeControl';

/**
 * Full video controls
 *
 * ExpandedControls currently disabled due to poor support for browser PictureInPicture API (~40%),
 * and minimal need for download functionality.
 */
export const Controls: FC<{ show: boolean }> = ({ show }) => {
  const layout = {
    gridRow: '1 / -1',
    gridColumn: '1 / -1',
    zIndex: '1',
  };

  return (
    <>
      {show && (
        <div
          role="presentation"
          style={{
            ...layout,
            // lighten up the video so the controls have more contrast
            backdropFilter: 'blur(1px) brightness(120%) saturate(50%)',
          }}
        />
      )}
      {show && (
        <div
          role="presentation"
          style={{
            ...layout,
            background:
              'var(--controls-background, linear-gradient(rgba(149,189,199,0.15), rgba(149,189,199,0.5)))',
          }}
        />
      )}
      <ChapterPreview show={show} />
      <ChapterRegions show={show} />
      <SeekControl show={show} />
      <TimelineControls show={show} />
      <PlayControl show={show} />
      <ControlsContainer css={[!show && tw`opacity-0`]}>
        <VolumeControl />
        <FullscreenControl />
        {/* <ExpandingControl /> */}
      </ControlsContainer>
    </>
  );
};

const ControlsContainer = tw.div`grid-row[3] grid-column[2 / 3] z-10 justify-self-end flex gap-3`;
