import React, { FC, useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import { OptionalChildrenProps } from '../../config';
import { useProviderState } from './StateProvider';
import { useEventListener } from './useEventListener';

export type VideoProps = OptionalChildrenProps & {
  /** that is: show NATIVE controls */
  controls?: boolean;
  poster?: string;
};

/** Subcomponent of VideoPlayer with chapter handling and HTML5 video element */
export const Video: FC<VideoProps> = ({ children, poster, controls }) => {
  const [{ videoElement: video, chapters }, dispatch] = useProviderState();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!video) {
      dispatch({ type: 'videoElement', payload: videoRef.current });
    }
    return () => {
      videoRef.current = null;
    };
  }, [videoRef, dispatch, video]);

  function useDispatchEvent(event, payload, key) {
    return useEventListener(
      event,
      () =>
        dispatch({
          type: key || event,
          payload,
        }),
      video,
    );
  }

  function resolveCurrentChapterFromCurrentTime(currentTime: number) {
    const currentChapter = chapters.find((_, chaptersIndex) => {
      const nextStartTime = chapters[chaptersIndex + 1]?.startTime ?? Infinity;

      if (
        currentTime >= chapters[chaptersIndex].startTime &&
        currentTime < nextStartTime
      ) {
        return true;
      }
    });

    if (currentChapter) {
      return currentChapter;
    }
  }

  useDispatchEvent('playing', true, undefined);
  useDispatchEvent('pause', false, 'playing');

  useEventListener(
    'timeupdate',
    () => {
      const currentChapter = resolveCurrentChapterFromCurrentTime(
        video?.currentTime,
      );

      dispatch({
        type: 'updateCurrents',
        payload: {
          currentTime: video?.currentTime,
          currentChapter: currentChapter ? currentChapter : chapters[0],
        },
      });
    },
    video,
  );

  return (
    // TODO: add captions to video
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <StyledVideo
      disablePictureInPicture
      poster={poster}
      ref={videoRef}
      controls={controls}
      css={[
        css`
          &::-webkit-media-controls-panel {
            background: linear-gradient(
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.1) 70%,
              rgba(22, 113, 167, 0.6)
            ) !important;
          }
        `,
      ]}
    >
      {children}
    </StyledVideo>
  );
};

const StyledVideo = tw.video`w-full grid-column-start[1] grid-column-end[-1] grid-row-start[1] grid-row-end[-1]`;
