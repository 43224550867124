import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import {
  VideoReducer,
  VideoReducerAction,
  VideoReducerState,
} from './video-player-types';

export const StateContext = createContext<{
  state: VideoReducerState;
  dispatch: Dispatch<VideoReducerAction>;
}>({ state: { chapters: [] }, dispatch: () => null });

export const StateProvider: FC<{
  reducer: VideoReducer;
  initialState: VideoReducerState;
  children: ReactNode;
}> = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useProviderState = (): [
  VideoReducerState,
  Dispatch<VideoReducerAction>,
] => {
  const { state, dispatch } = useContext(StateContext);
  return [state, dispatch];
};
