import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
  useListboxContext,
} from '@reach/listbox';
import '@reach/listbox/styles.css';
import React, { FC, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { CustomCssProps } from '../../config';
import { FullscreenControl } from './FullscreenControl';
import { useProviderState } from './StateProvider';
import { useEventListener } from './useEventListener';
import { formatSeconds } from './utils';
import { Label } from './video-player-shared';
import isIosSafari from '@braintree/browser-detection/is-ios-safari';

/**
 * Simplified controls shown when player is below `smallControlsMinWidth`
 */
export const MinimalControls: FC = () => {
  return (
    <ControlsContainer className="tw-reset">
      <PlayControl />
      <ChapterSelect />
      <VolumeControl />
      <FullscreenControl minimal />
    </ControlsContainer>
  );
};

const ChapterSelectButton: FC = () => {
  const [{ currentChapter }] = useProviderState();
  const { isExpanded } = useListboxContext();

  return (
    <ListboxButton
      arrow="▼"
      css={[
        tw`text-xs tracking-[0.35px] uppercase w-full border-none whitespace-nowrap rounded-2xl mx-4 p-3 py-2 cursor-pointer font-normal`,
        isExpanded
          ? tw`bg-brand-blue text-[#eeedf0]`
          : tw`bg-[#eeedf0] text-brand-blue-dark`,
      ]}
    >
      <span>
        <strong
          css={[
            isExpanded
              ? tw`bg-brand-blue text-[#eeedf0]`
              : tw`bg-[#eeedf0] text-brand-blue-dark`,
          ]}
        >
          Currently:
        </strong>{' '}
        Chapter {currentChapter?.chapter || '1'}
      </span>
    </ListboxButton>
  );
};

const ChapterSelect: FC<{ showChapterThumbs?: boolean }> = ({
  showChapterThumbs = false,
}) => {
  const [{ chapters, currentChapter, videoElement: video }] =
    useProviderState();

  function selectChapter(value: string) {
    if (video) {
      video.currentTime =
        chapters.find(chap => `${chap.chapter}` === value)?.startTime ?? 0;
      video.play();
    }
  }

  return (
    <ListboxInput
      defaultValue="1"
      onChange={selectChapter}
      css={[tw`flex flex-grow bg-none self-stretch items-center`]}
    >
      {chapters.length > 1 && <ChapterSelectButton />}
      {/* TODO: add colors to theme */}
      <ListboxPopover tw="mt-2 p-0! box-shadow[rgba(0, 0, 0, 0.2) 0px 2px 16px 0px] rounded-2xl outline-none bg-[#eeedf0] border-[#cccad3]!">
        <ListboxList>
          {chapters.map(chap => {
            if (chap.chapter === currentChapter?.chapter) {
              return null;
            }

            return (
              <ListboxOption
                key={chap.chapter}
                value={`${chap.chapter}`}
                css={[
                  tw`whitespace-normal flex flex-col items-start font-primary mb-2 p-2 text-brand-blue-dark`,
                  css`
                    &[data-reach-listbox-option] {
                      padding: 0.5rem;
                      &:first-of-type {
                        border-radius: 1rem 1rem 0 0;
                      }
                      &:last-of-type {
                        border-radius: 0 0 1rem 1rem;
                      }
                    }
                    &[data-reach-listbox-option][data-current-nav] {
                      background-color: #1671a7;
                    }
                  `,
                ]}
              >
                {showChapterThumbs && (
                  <img
                    src={chap.image.url}
                    alt={chap.image.alt}
                    tw="max-w-full w-full mr-2 rounded mb-2"
                  />
                )}
                <Label tw="m-0">
                  <span tw="uppercase font-extrabold block">
                    Chapter {chap.chapter}
                  </span>
                  <span>{chap.title}</span>
                </Label>
              </ListboxOption>
            );
          })}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
};

const PlayControl: FC = () => {
  const [{ playing, videoElement: video, currentTime }] = useProviderState();
  const [hover, setHover] = useState(false);

  return (
    <PlayToggleButton
      aria-label={playing ? 'pause' : 'play'}
      onClick={() => (playing ? video?.pause() : video?.play())}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <PlayIconContainer
        role="presentation"
        css={[tw`bg-brand-blue-dark`, hover && tw`bg-brand-blue`]}
      >
        {playing ? <PauseIcon /> : <PlayIcon />}
      </PlayIconContainer>

      <Label tw="block text-brand-blue-dark ml-2">
        {formatSeconds(currentTime)}
      </Label>
    </PlayToggleButton>
  );
};

const VolumeControl: FC = () => {
  const [{ videoElement: video }] = useProviderState();
  const [hover, setHover] = useState(false);
  const [mute, setMute] = useState(false);

  useEventListener(
    'volumechange',
    e => {
      if (video?.volume === 0) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    video,
  );

  function handleMute() {
    if (video) {
      if (video.volume > 0) {
        video.volume = 0;
      } else {
        video.volume = 0.75;
      }
    }
  }

  return (
    <VolumeButton
      onClick={handleMute}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      aria-label="volume"
    >
      <VolumeIcon mute={mute} />
    </VolumeButton>
  );
};

const ControlsContainer = tw.div`flex col-start-1 col-end-[-1] justify-between mt-3 items-center px-2 pb-2`;
const VolumeButton = tw.button`grid-row[3] col-start-2 col-end-3 justify-self-end m-0 rounded-full h-8 w-8 bg-brand-blue-dark text-white text-center cursor-pointer`;
export const PlayToggleButton = styled.button<CustomCssProps>(
  ({ customCss }) => {
    return [
      tw`m-0 p-0 border-none pointer-events-auto bg-none bg-transparent flex justify-center items-center cursor-pointer`,
      customCss,
    ];
  },
);

export const PlayIconContainer = styled.span<CustomCssProps>(
  ({ customCss }) => {
    return [
      tw`rounded-full h-8 w-8 flex justify-center items-center`,
      customCss,
    ];
  },
);

const PlayIcon: FC = () => (
  <svg
    className="tw-reset"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="#fff"
    viewBox="0 0 24 24"
    tw="ml-0 h-4 w-4"
  >
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon: FC = () => (
  <svg
    className="tw-reset"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 8 10"
    tw="ml-0 h-3 w-2.5"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#fff" transform="translate(-698 -1155)">
        <g transform="translate(681 1139)">
          <g transform="translate(17 16)">
            <path d="M2.17748047e-13 1.99650013L2.16012924 0.352095701 2.16012924 9.31700061 2.17748047e-13 9.31700061z" />
            <path d="M5.50000012 1.99650013L7.66012936 0.352095701 7.66012936 9.31700061 5.50000012 9.31700061z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const VolumeIcon: FC<{ mute?: boolean }> = ({ mute }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 18"
    css={[
      tw` p-0 h-4 w-4 inline-block margin-left[1px] margin-bottom[3px]`,
      isIosSafari() && tw`margin-left[-7px] mb-0`,
    ]}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g fill="#fff" stroke="#fff" strokeWidth="1.204">
        <path
          d="M9.10712317 1.31028061L11.1542972 2.49221711 4.60334022 13.8388075 2.55616615 12.656871 2.55616615 5.0924774z"
          transform="rotate(-30 6.855 7.575)"
        />
        <path d="M0 4.738H5.201V10.411000000000001H0z" />
      </g>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.56"
        transform="translate(10.874 2.208)"
        opacity={mute ? '0.3' : '1'}
      >
        <path d="M.448 10.726A5.676 5.676 0 00.426 0M0 7.078a1.967 1.967 0 00.192-3.23" />
      </g>
    </g>
  </svg>
);
