import screenfull from 'screenfull';
import React, { FC, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useProviderState } from './StateProvider';
import isIosSafari from '@braintree/browser-detection/is-ios-safari';

type FullscreenControlProps = {
  minimal?: boolean;
};

/**
 * Fullscreen control button
 */
export const FullscreenControl: FC<FullscreenControlProps> = ({ minimal }) => {
  const [hover, setHover] = useState(false);
  const [{ videoElement: video }] = useProviderState();

  const handleFS = () => {
    if (screenfull.isEnabled) {
      const r = screenfull.request(video);
      r.catch(reason => {
        console.error('fullscreen request failed', reason);
      });
    } else {
      if (isIosSafari()) {
        // on iPhones, playing automatically makes it fullscreen
        video.play();
      }
    }
  };

  return (
    <>
      <FullscreenButton
        minimal={minimal}
        onClick={handleFS}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        tw="focus:(outline[2px dotted #4A387A])"
        aria-label="fullscreen"
      >
        <FullscreenIcon minimal={minimal} hover={hover} />
      </FullscreenButton>
    </>
  );
};

const FullscreenIcon: FC<{ minimal?: boolean; hover: boolean }> = ({
  minimal,
  hover,
}) => {
  return (
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      tw="width[15px]! height[15px]! m-0!"
    >
      <title>Full Screen</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="mobile_timelinebtns"
          transform="translate(-362.000000, -13.000000)"
        >
          <g id="Group-3" transform="translate(299.000000, 0.000000)">
            <circle
              id="Oval-Copy-9"
              fill={minimal ? '#fff' : hover ? '#1671a7' : '#43798d'}
              opacity="0.079269"
              cx="70.3547023"
              cy="20.7929034"
              r="20.7929034"
            ></circle>
            <g
              id="Group-9"
              transform="translate(64.425646, 14.190662)"
              stroke={minimal ? '#fff' : hover ? '#1671a7' : '#43798d'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.99804001"
            >
              <polyline
                id="Path"
                points="0 12.4877501 0 0 8.99118004 0"
                stroke={minimal ? '#fff' : hover ? '#1671a7' : '#43798d'}
              ></polyline>
              <polyline
                id="Path"
                transform="translate(7.992160, 6.243875) rotate(-180.000000) translate(-7.992160, -6.243875) "
                points="3.49657002 12.4877501 3.49657002 1.20261205e-13 12.4877501 3.15304041e-14"
                stroke={minimal ? '#fff' : hover ? '#1671a7' : '#43798d'}
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const FullscreenButton = styled.button(({ minimal }: { minimal?: boolean }) => [
  tw`border-none! rounded-2xl! flex! justify-center!
  items-center! cursor-pointer! relative! h-8! w-8! p-0! m-0!
  mt-4! bg-brand-white!
`,
  minimal &&
    tw`bg-brand-blue-dark! m-0! ml-2! rounded-full hover:bg-brand-blue!`,
]);
