import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useProviderState } from './StateProvider';
import { formatSeconds } from './utils';
import { Chapter as ChapterType, Chapters } from './video-player-types';

export const ChapterRegions: FC<{ show: boolean }> = ({ show }) => {
  const [{ chapters }] = useProviderState();

  if (!chapters) return null;

  return (
    <ChapterRegionContainer
      show={show}
      role="presentation"
      chapters={chapters}
      className="video-player-chapters"
    >
      {chapters.map(chapter => (
        <ChapterRegion chapter={chapter} key={chapter.chapter} />
      ))}
    </ChapterRegionContainer>
  );
};

const ChapterRegion: FC<{ chapter: ChapterType }> = ({ chapter }) => {
  const [{ preview, videoElement: video }, dispatch] = useProviderState();

  const previewing = preview === chapter.chapter;

  return (
    <SkipToButton
      onMouseEnter={() =>
        dispatch({
          type: 'preview',
          payload: chapter.chapter,
        })
      }
      onMouseLeave={() =>
        dispatch({
          type: 'preview',
          payload: null,
        })
      }
      onFocus={() =>
        dispatch({
          type: 'preview',
          payload: chapter.chapter,
        })
      }
      onBlur={() =>
        dispatch({
          type: 'preview',
          payload: null,
        })
      }
      onClick={() => {
        if (video) {
          video.currentTime = chapter.startTime;
          video.play();
        }
      }}
      style={{
        background: previewing ? 'rgba(111,181,209,0.5)' : 'none', // keep this in sync w/SkipToButton:hover styled component, below
      }}
    >
      {previewing && (
        <SkipToText>
          <SkipToIcon /> {formatSeconds(chapter.startTime)}
        </SkipToText>
      )}
    </SkipToButton>
  );
};

const SkipToIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9"
      viewBox="0 0 15 9"
      tw="inline-block mb-1 width[15px]! height[9px]! ml-0!"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g
          fill="#4A3A78"
          stroke="#4A387A"
          strokeWidth="1.466"
          transform="translate(-1118 -1609)"
        >
          <g transform="translate(1113 1598)">
            <g transform="translate(6 12)">
              <path
                d="M5.05681377 1.5999999L5.59999967 5.59999967 1.5999999 5.05681377z"
                transform="rotate(-45 3.6 3.6)"
              />
              <path
                d="M10.6568134 1.5999999L11.1999993 5.59999967 7.19999957 5.05681377z"
                transform="rotate(-45 9.2 3.6)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const ChapterRegionContainer = styled.div<{
  chapters: Chapters;
  show: boolean;
}>(({ chapters, show }) => {
  const chapterStyles = chapters.map(c => `${c.duration}fr`).join(' ');

  return [
    tw`grid row-start-1 row-end-3 col-start-2 col-end-3 z-10`,
    css`
      grid-template-columns: ${chapterStyles};
    `,
    !show && tw`opacity-0`,
  ];
});

const SkipToButton = tw.button`
  border-none p-0 m-0 cursor-pointer flex flex-col justify-end
  items-center relative color[inherit] border-radius[0%]!
  hover:(background[rgba(111,181,209,0.5)]!)
`;
const SkipToText = tw.span`
  absolute left-1/2 transform[translateX(-50%)] whitespace-nowrap bg-brand-white
  rounded-2xl uppercase leading-none p-[5px] px-2.5 mb-2
`;
