import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { CCLVideo } from '../talking';

const UTUCVideoSection = () => {
  return (
    <section
      className="subsection"
      aria-label="UTUC video presentation"
      tw="mt-12"
    >
      <h3 className="subsection__title">
        Watch Uncovering Upper Tract Urothelial Cancer (UTUC), a video
        presentation by UTUC experts on diagnosis, treatment options, and more
      </h3>
      <CCLVideo />
    </section>
  );
};

export default UTUCVideoSection;
