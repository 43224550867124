import React, { FC, useState } from 'react';
import tw from 'twin.macro';
import { useProviderState } from './StateProvider';
import { useEventListener } from './useEventListener';

export const VolumeControl: FC = () => {
  const [{ videoElement: video }] = useProviderState();
  const [hover, setHover] = useState(false);
  const [mute, setMute] = useState(false);

  useEventListener(
    'volumechange',
    e => {
      if (video?.volume === 0) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    video,
  );

  function handleMute() {
    if (video) {
      if (video.volume > 0) {
        video.volume = 0;
      } else {
        video.volume = 0.75;
      }
    }
  }

  return (
    <button
      tw="border-none m-0 rounded-2xl! h-8! w-8! flex justify-center items-center cursor-pointer mt-4 bg-brand-white! p-0!"
      css={[
        tw`focus:(outline[2px dotted #4A387A])`,
        hover && tw`h-8! w-8! p-0! m-0! mt-4!`,
      ]}
      onClick={handleMute}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <VolumeIcon hover={hover} mute={mute} />
    </button>
  );
};

const VolumeIcon: FC<{ hover: boolean; mute: boolean }> = ({ hover, mute }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      tw="m-0!"
    >
      <title>Volume</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g transform="translate(-1095 -1402)">
          <g transform="translate(1083 1390)">
            <g transform="translate(13 13.108)">
              <g
                fill={hover ? '#43798d' : '#5B9C7'}
                stroke={hover ? '#1671a7' : '#43798d'}
                strokeWidth="1.204"
              >
                <path
                  d="M9.10712317 1.31028061L11.1542972 2.49221711 4.60334022 13.8388075 2.55616615 12.656871 2.55616615 5.0924774z"
                  transform="rotate(-30 6.855 7.575)"
                />
                <path d="M0 4.738H5.201V10.411000000000001H0z" />
              </g>
              <g
                stroke={hover ? '#1671a7' : '#43798d'}
                strokeLinecap="round"
                strokeWidth="1.56"
                transform="translate(10.874 2.208)"
                opacity={mute ? '0.3' : '1'}
              >
                <path d="M.448 10.726A5.676 5.676 0 00.426 0M0 7.078a1.967 1.967 0 00.192-3.23" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
