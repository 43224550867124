import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useProviderState } from './StateProvider';
import { Chapter, Chapters } from './video-player-types';

export const TimelineControls: FC<{ show: boolean }> = ({ show }) => {
  return (
    <>
      <TimelineContainer css={[!show && tw`opacity-0`]} role="presentation" />
      <Timeline />
    </>
  );
};

const Timeline: FC = () => {
  const [{ chapters }] = useProviderState();

  if (!chapters) return null;

  return (
    <SegmentContainer role="presentation" chapters={chapters}>
      {chapters.map(chapter => (
        <Segment chapter={chapter} key={chapter.chapter + chapter.title} />
      ))}
    </SegmentContainer>
  );
};
const Segment: FC<{ chapter: Chapter }> = ({ chapter }) => {
  const [{ videoElement: video, segment, preview }, dispatch] =
    useProviderState();
  const active = chapter.chapter === segment;
  const highlight = active || preview === chapter.chapter;

  return (
    <SegmentButton
      className="tw-reset"
      onMouseEnter={() =>
        dispatch({
          type: 'segment',
          payload: chapter.chapter,
        })
      }
      onMouseLeave={() =>
        dispatch({
          type: 'segment',
          payload: null,
        })
      }
      onFocus={() =>
        dispatch({
          type: 'segment',
          payload: chapter.chapter,
        })
      }
      onBlur={() =>
        dispatch({
          type: 'segment',
          payload: null,
        })
      }
      onClick={() => {
        if (video) {
          video.currentTime = chapter.startTime;
          video.play();
        }
      }}
    >
      {active && <SegmentToolTip>Chapter {chapter.chapter}</SegmentToolTip>}
      <div
        role="presentation"
        style={{
          height: '4px',
          transform: 'skewX(135deg)',
          marginBottom: 'var(--gutter)',
          background: highlight
            ? 'rgba(111,181,209,1.0)'
            : 'rgba(255,255,255,0.5)',
          position: 'relative',
          marginTop: '64px',
        }}
      />
    </SegmentButton>
  );
};

const SegmentToolTip: FC = ({ children }) => {
  return <TooltipLabel>{children}</TooltipLabel>;
};
const TimelineContainer = tw.div`
  grid grid-row[3] grid-column-start[1] grid-column-end[-1]
  z-10 border-top[solid 1px rgba(255,255,255,0.5)]
`;

const SegmentContainer = styled.div<{ chapters: Chapters }>(({ chapters }) => {
  const chapterStyles = chapters.map(c => `${c.duration}fr`).join(' ');
  return [
    tw`grid row-start-3 row-end-[-1] col-start-2 col-end-3 z-10 gap-[3px]`,
    css`
      grid-template-columns: ${chapterStyles};
    `,
  ];
});

const SegmentButton = tw.button`border-0 bg-none p-0 m-0 self-stretch color[inherit] relative border-radius[0%]! all[unset]!`;
const TooltipLabel = tw.p`
  text-xs tracking-[0.35px] uppercase absolute left-1/2 translate-x-1/2
  whitespace-nowrap mt-8 bg-brand-white rounded-2xl p-[5px 10px]
`;
