import './faq.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Definition from '../definition/definition';
import CausesItems from '../causesItems/causesItems';
import ExternalLink from '../externalLink';
import 'twin.macro';
import { pushCustomEvent } from '../../utils/tagging';

const FAQSection = () => (
  <div>
    <section className="subsection" aria-label="What is UTUC" tw="mt-12">
      <div className="subsection__content_section">
        <h3 className="subsection__title">
          What is upper tract urothelial cancer (UTUC)?
        </h3>
        <p>
          <Definition
            term="Upper tract urothelial cancer"
            id="upper_tract_urothelial_cancer"
          />
          , or UTUC, is a cancer that happens in the lining of the kidneys or{' '}
          <Definition term="ureters" id="ureter" />. It’s called upper tract
          because the kidneys and the ureters are the upper part of the{' '}
          <Definition term="urinary tract" id="urinary_tract" />.
        </p>
        <p>
          Your <Definition term="urologist" id="urologist" /> might call this
          type of cancer by other names, such as
        </p>
        <ul className="bullet-list">
          <li className="bullet-list__item">
            <span>Cancer of the upper urinary tract</span>
          </li>
          <li className="bullet-list__item">
            <span>Upper tract urothelial carcinoma</span>
          </li>
          <li className="bullet-list__item">
            <span>Renal pelvis cancer</span>
          </li>
          <li className="bullet-list__item">
            <span>Ureteral cancer</span>
          </li>
          <li className="bullet-list__item">
            <span>Urinary tract cancer</span>
          </li>
          <li className="bullet-list__item">
            <span>Upper tract cancer</span>
          </li>
          <li className="bullet-list__item">
            <span>Cancer in the lining of the kidney</span>
          </li>
        </ul>
        <p tw="pt-8">
          For more information about UTUC, visit the{' '}
          <Link to="/about-the-urinary-tract">About the urinary tract</Link> and{' '}
          <Link to="/understanding-utuc">Understanding UTUC</Link> pages.
        </p>
      </div>
    </section>
    <section className="subsection" aria-label="What is Urothelial Cancer">
      <div className="subsection__content_section">
        <h3 className="subsection__title">What is urothelial cancer?</h3>
        <p>
          <Definition term="Urothelial cancer" id="urothelial_cancer" /> is
          cancer that happens in the urinary tract. Your doctor might call it
          urinary tract cancer or{' '}
          <Definition term="urothelial carcinoma" id="urothelial_cancer" />. The
          word urothelial (uro-THEE-lee-uhl) is used because these types of
          cancer happen in the tissue on the inside of the urinary tract. That
          tissue is called the <Definition term="urothelium" id="urothelium" />{' '}
          (uro-THEE-lee-um), and it lines the inside of all 4 major parts of the{' '}
          <Definition term="urinary tract" id="urinary_tract" />.
          <p>
            For more information about urothelial cancer, visit the{' '}
            <Link to="/about-the-urinary-tract">About the urinary tract</Link>{' '}
            page.
          </p>
        </p>
      </div>
    </section>
    <section className="subsection" aria-label="Why do people get it?">
      <div className="callout full">
        <h3 className="callout__title">Why do people get UTUC?</h3>
        <p>
          It’s hard to know exactly what causes UTUC, but there are several
          things that could increase your chances:
        </p>
        <CausesItems styleName="faq__causes_items" colorIcons={false} />
      </div>
    </section>
    <section className="subsection" aria-label="Is it dangerous?">
      <div className="subsection__content_section">
        <h3 className="subsection__title">Is UTUC dangerous?</h3>
        <p>
          Like all cancers, UTUC is a serious disease. If it's not treated, it
          can cause kidney damage, spread to other parts of the body, or
          potentially even lead to death. To understand how dangerous UTUC is,
          it’s important to find out the <Definition term="grade" id="grade" />{' '}
          and <Definition term="stage" id="stage" /> of the cancer.
        </p>
        <p>
          To learn more about grade and stage, visit the{' '}
          <Link to="/understanding-utuc">Understanding UTUC</Link> page or the{' '}
          <Link to="/helpful-resources#glossary">Glossary of key terms</Link>.
        </p>
      </div>
    </section>
    <section className="subsection" aria-label="Will my UTUC come back?">
      <h3 className="subsection__title">Will my UTUC come back?</h3>
      <p>
        Low-grade UTUC can come back after minor surgery. Minor surgery is
        recommended so that patients keep their kidneys intact, but this comes
        at the risk of <Definition term="recurrence" id="recurrence" />.
      </p>
      <ul tw="my-4 pl-4">
        <li className={`bullet-list__item`}>
          <span>
            Studies have shown that about 65% of patients experience recurrence
            following{' '}
            <Definition
              term="endoscopic treatment"
              id="endoscopic_management"
            />
          </span>
        </li>
      </ul>
      <p>
        High-grade UTUC is aggressive and invasive. For this reason, the entire
        kidney and ureter are usually taken out, so recurrence in the kidney
        and/or ureter is less of a concern.
      </p>
    </section>
    <section className="subsection" aria-label="How can it be treated">
      <div className="subsection__content_section">
        <h3 className="subsection__title">How can UTUC be treated?</h3>

        <p>
          There are several ways to treat UTUC. The right treatment depends on
          how dangerous the cancer is.
        </p>

        <div className="faq__grades_container">
          <div className="faq__grade">
            <h4 className="definition-title">
              <Definition term="Low grade" id="low_grade" />
            </h4>
            <p>
              UTUC is usually not too dangerous. It's not very aggressive and is
              slow to spread to other parts of the body. But it's still
              important to get it treated to avoid serious kidney damage. There
              are several different ways to treat it.&nbsp;
              <ExternalLink
                url="https://www.jelmyto.com/"
                modalContent="This link will take you to a website to learn about a prescription treatment option for low-grade UTUC."
                handleBeforeRoute={() =>
                  pushCustomEvent({
                    tagName: 'Jelmyto-link-click',
                    options: {},
                  })
                }
              >
                Click here
              </ExternalLink>
              &nbsp;to learn about a nonsurgical treatment option for low-grade
              UTUC.
            </p>
          </div>
          <div className="faq__grade">
            <h4 className="definition-title">
              <Definition term="High grade" id="high_grade" />
            </h4>
            <p>
              UTUC is usually more dangerous. It can be aggressive and might
              spread to other parts of your urinary tract or other parts of your
              body. There is only one good way to treat high-grade UTUC—surgery.
            </p>
          </div>
        </div>
        <p>
          Be sure to talk to your doctor about choosing a treatment. To see what
          questions to ask, visit the{' '}
          <Link to="/treatment-options#talk_with_your_doctor">
            Talk with your doctor
          </Link>{' '}
          page.
        </p>
      </div>
    </section>
    <section className="subsection" aria-label="What are grade and stage?">
      <div className="callout full">
        <h3 className="callout__title">
          What do words like grade and stage mean?
        </h3>
        <p>
          The <Definition term="grade" id="grade" /> of your UTUC depends on how
          aggressive the cancer is. There are 2 grades of UTUC:{' '}
          <Definition term="low grade" id="low_grade" /> and{' '}
          <Definition term="high grade" id="high_grade" />. Low grade is usually
          not very aggressive and is slow to spread. High grade is more
          dangerous. It can be aggressive and could spread to other parts of
          your urinary tract or other parts of your body.
        </p>
        <p>
          The <Definition term="stage" id="stage" /> of your UTUC depends on how
          deep into your kidney or ureter your cancer has grown. The stage of
          your cancer could be anywhere from 0 to 4. It can be hard to know what
          stage UTUC is, and your urologist might not always be able to tell.
        </p>
        <p>
          For more information about grade and stage, visit the{' '}
          <Link to="/understanding-utuc" tw="color[#6FB5D1]!">
            Understanding UTUC
          </Link>{' '}
          page.
        </p>
        <h3 className="callout__title" tw="mt-16!">
          What about medium-grade UTUC?
        </h3>
        <p>
          UTUC is classified as either low grade or high grade. There is no
          medium-grade cancer in UTUC.
        </p>
        <p>
          For more information about grade and stage, visit the{' '}
          <Link to="/understanding-utuc" tw="color[#6FB5D1]!">
            Understanding UTUC
          </Link>{' '}
          page.
        </p>
        <h3 className="callout__title" tw="mt-16!">
          How do I find out what grade and stage my UTUC is?
        </h3>
        <p>
          Talk to your urologist. The results of your{' '}
          <Definition term="biopsy" id="biopsy" /> should give your urologist
          more information about the grade and stage of your UTUC.
        </p>
      </div>
    </section>
    <section className="subsection" aria-label="What to expect from surgery">
      <div className="subsection__content_section">
        <h3 className="subsection__title">
          What can I expect if I need to have surgery to remove my UTUC?
        </h3>
        <p>
          There are a lot of important things to think about before you have
          surgery, even if it’s a minor operation. Visit the{' '}
          <Link to="/treatment-options">Getting treated</Link> page for
          information about what surgery could mean for you.
        </p>
        <h3 className="subsection__title" tw="mt-16!">
          Are there any medications that I can take for UTUC?
        </h3>
        <p>
          Right now, there's one treatment approved by the FDA to treat UTUC,
          and new treatments for cancer are always being studied.
        </p>
        <p>
          To see what new treatments are being tested for urinary tract cancers,
          visit the list of clinical studies on the{' '}
          <ExternalLink url="https://clinicaltrials.bcan.org">
            Bladder Cancer Advocacy Network (BCAN)
          </ExternalLink>{' '}
          website or the{' '}
          <ExternalLink url="https://www.clinicaltrials.gov/ct2/results?cond=upper+tract+urothelial&term=&cntry=&state=&city=&dist=">
            National Library of Medicine's study registry
          </ExternalLink>
          .
        </p>
      </div>
    </section>
  </div>
);

export default FAQSection;
