import { useFocusWithin } from '@react-aria/interactions';
import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useIsMobile } from '../../hooks/useMedia';
import { Controls } from './Controls';
import { MinimalControls } from './MinimalControls';
import { useProviderState } from './StateProvider';
import { Video } from './Video';
import { Label } from './video-player-shared';

const smallControlsMinWidth = 640;

export function VideoPlayerLayout({ children, poster, small, ...rest }) {
  const [showControls, setShowControls] = useState(true); // show controls by default on page load
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [{ videoElement: video, chapters }] = useProviderState();
  // Show video controls if any descendant has focus
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: isFocusWithin => {
      setShowControls(isFocusWithin);
    },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    video && video.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      video &&
        video.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [video]);

  const isMobile = useIsMobile();
  if (typeof small !== 'boolean') {
    // Ideally, in the future, this would use something to calculate the size of the actual box
    // (rather than the window), but this is very complicated to work into the current flow.
    small = isMobile;
  }

  const showChapters = chapters.length > 0 && !small;

  return (
    <>
      <div
        role="presentation"
        {...rest}
        {...focusWithinProps}
        css={[tw`font-primary`, showChapters && tw`my-4`]}
        className="video-player"
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        {showChapters && (
          <Label
            className="tw-reset"
            tw="text-xl text-brand-blue uppercase! my-4!"
          >
            Hover over video for chapters
          </Label>
        )}
        <VideoContainer role="presentation">
          {video && !small && !isMobile && <Controls show={showControls} />}
          <Video poster={poster} controls={isFullScreen}>
            {children}
          </Video>
          {video && small && <MinimalControls />}
        </VideoContainer>
      </div>
    </>
  );
}
const VideoContainer = styled.div(() => [
  tw`grid grid-template-columns[2rem auto 2rem] grid-template-rows[2rem auto 96px 2rem]
     border-2 border-solid border-brand-blue border-opacity-20 lg:(border-4)`,
]);
