import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import logoBCAN from '../../images/logo-bcan.jpg';
import logoCC from '../../images/logo-cancer-care.png';
import logoUTUCFB from '../../images/logo-utuc-fb.svg';
import logoNIH from '../../images/logo-nih.jpg';
import logoUCF from '../../images/logo-ucf.jpg';
import ExternalLink from '../externalLink';

type OrgSectionProps = {
  //
};

/**
 * Organization and tools tab section
 */
const OrgSection: FC<OrgSectionProps> = props => {
  return (
    <section
      id="org"
      className="subsection"
      aria-label="Organizations and Tools"
      tw="mt-12"
    >
      <h3 className="subsection__title">
        The following organizations and online community can help provide you
        with more information and support
      </h3>
      <div>
        <div tw="flex flex-col gap-8 md:gap-4">
          <ResourceWrapper>
            <ExternalLink
              url="https://bcan.org"
              tw="flex justify-center items-center md:inline"
            >
              <LogoImg
                src={logoBCAN}
                alt="Bladder Cancer Advocacy Network Logo"
              />
            </ExternalLink>
            <ResourceCopyWrapper>
              <p>
                The Bladder Cancer Advocacy Network (BCAN) is a community of
                patients, caregivers, and medical professionals advocating for
                awareness and advancements in treatment.
              </p>
              <ul tw="my-2">
                <li className="bullet-list__item">Join a support group</li>
                <li className="bullet-list__item">
                  Learn about the research happening
                </li>
                <li className="bullet-list__item">
                  Get toolkits and downloadable resources
                </li>
              </ul>
            </ResourceCopyWrapper>
            <ExternalLink url="https://bcan.org" tw="h-full flex items-center">
              <div className="button--round treatment__button button-and-title__button">
                <span className="balance-text">Visit the BCAN website</span>
              </div>
            </ExternalLink>
          </ResourceWrapper>
          <ResourceWrapper>
            <ExternalLink
              url="https://www.cancercare.org/"
              tw="flex justify-center items-center md:inline"
            >
              <LogoImg src={logoCC} alt="CancerCare Logo" />
            </ExternalLink>
            <ResourceCopyWrapper>
              <p>
                Cancer<span tw="italic">Care</span>® provides patients and their
                loved ones with counseling, case management assistance, support
                group information, education, and financial assistance to
                eligible individuals.
              </p>
            </ResourceCopyWrapper>
            <ExternalLink
              url="https://www.cancercare.org/"
              tw="h-full flex items-center"
            >
              <div className="button--round treatment__button button-and-title__button">
                <span className="balance-text">
                  Visit the Cancer<span tw="italic">Care</span> website
                </span>
              </div>
            </ExternalLink>
          </ResourceWrapper>
          <ResourceWrapper>
            <ExternalLink
              url="https://www.clinicaltrials.gov/ct2/results?cond=upper+tract+urothelial&term=&cntry=&state=&city=&dist="
              tw="flex justify-center items-center md:inline"
            >
              <LogoImg src={logoNIH} alt="National Library of Medicine Logo" />
            </ExternalLink>
            <ResourceCopyWrapper>
              <p>
                The US National Library of Medicine keeps a registry of clinical
                studies, which test new treatments that aren’t approved by the
                US Food and Drug Administration (FDA) yet. The treatments
                haven’t been fully tested yet, so how well they work and their
                side effects are still being studied. Talk to your doctor about
                whether clinical studies might be right for you.
              </p>
            </ResourceCopyWrapper>
            <ExternalLink
              url="https://www.clinicaltrials.gov/ct2/results?cond=upper+tract+urothelial&term=&cntry=&state=&city=&dist="
              tw="h-full flex items-center"
            >
              <div className="button--round treatment__button button-and-title__button">
                <span className="balance-text">Search the registry</span>
              </div>
            </ExternalLink>
          </ResourceWrapper>
          <ResourceWrapper>
            <ExternalLink
              url="https://www.urologyhealth.org/"
              tw="flex justify-center items-center md:inline"
            >
              <LogoImg src={logoUCF} alt="Urology Care Foundation Logo" />
            </ExternalLink>
            <ResourceCopyWrapper>
              <div tw="w-full">
                <p>
                  The Urology Care Foundation™ supports urologic research and
                  patient education.
                </p>
                <ul tw="my-2">
                  <li className="bullet-list__item">
                    Reliable information about urologic conditions and
                    treatments
                  </li>
                  <li className="bullet-list__item">
                    Free printed guides, fact sheets, brochures, and tools
                  </li>
                  <li className="bullet-list__item">
                    <span tw="italic contents" aria-label="UrologyHealth extra">
                      UrologyHealth extra
                    </span>
                    ®, a free magazine with the latest news in urology
                  </li>
                </ul>
              </div>
            </ResourceCopyWrapper>
            <ExternalLink
              url="https://www.urologyhealth.org/"
              tw="h-full flex items-center"
            >
              <div className="button--round treatment__button button-and-title__button">
                <span className="balance-text">
                  Visit the Urology Care Foundation website
                </span>
              </div>
            </ExternalLink>
          </ResourceWrapper>
          <ResourceWrapper tw="items-center!">
            <LogoImgLink
              href="https://www.facebook.com/uncoveringUTUC/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoImg src={logoUTUCFB} alt="UTUC and Facebook Logos" />
            </LogoImgLink>
            <ResourceCopyWrapper>
              <p>
                Join the community dedicated to raising UTUC awareness where you
                can connect, learn, and find support.
              </p>
            </ResourceCopyWrapper>
            <div tw="w-full h-full flex items-center">
              <LinkButton
                href="https://www.facebook.com/uncoveringUTUC/"
                target="_blank"
                rel="noopener noreferrer"
                className="button--round treatment__button button-and-title__button"
              >
                <span className="balance-text">Visit the Facebook page</span>
              </LinkButton>
            </div>
          </ResourceWrapper>
          <p tw="color[#43798D] my-8">
            The third-party organizations listed above are not affiliated with
            or endorsed by UroGen® Pharma. All trademarks are the property of
            their respective owners.
          </p>
          <div>
            <h3 className="subsection__title">
              Bring the following downloadable resource to your next doctor's
              appointment
            </h3>
            <h4 tw="color[#43798D] uppercase mt-12 mb-4">
              Doctor discussion guide{' '}
            </h4>
            <DiscussionGuideWrapper>
              <p tw="color[#43798D] text-2xl">
                To make sure you’re ready to talk to your doctor, print out this
                detailed guide that includes helpful questions and a diagram so
                you can learn more about your UTUC.
              </p>
              <LinkButton
                download
                href="/pdf/discussion-guide.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="button--round treatment__button button-and-title__button"
                tw="margin[inherit]"
              >
                <span className="balance-text">Download PDF</span>
              </LinkButton>
            </DiscussionGuideWrapper>
          </div>
        </div>
      </div>
    </section>
  );
};

const ResourceWrapper = tw.div`
  flex flex-col align-items[stretch]! gap-4 my-4
  md:(
    grid grid-template-columns[1fr minmax(25ch, 100%) minmax(280px, 1fr)]
    grid-template-rows[1fr] justify-center items-start)`;
const ResourceCopyWrapper = tw.div`flex flex-col w-full min-width[2fr] px-4`;
const LogoImg = tw.img`max-width[250px] w-full height[max-content] md:width[120px]`;
const LogoImgLink = tw.a`flex justify-center items-center md:inline`;
const LinkButton = tw.a``;
const DiscussionGuideWrapper = tw.div`flex flex-col gap-4 md:(grid grid-template-columns[2fr 1fr] gap-24)`;

export default OrgSection;
