import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import './glossary.scss';

import glossaryTerms from '../../data/glossaryTerms';

type GlossarySectionProps = {
  //
};

/**
 * Glossary section tab
 */
const GlossarySection: FC<GlossarySectionProps> = props => {
  return (
    <div className="glossary__container">
      <section className="subsection" aria-label="glossary terms">
        <div className="subsection__content_section">
          <ul className="glossary__items">
            {glossaryTerms.map((termItem, index) => {
              const { id, term, definition, displayTerm } = termItem;
              return (
                <li className="glossary__item" key={id}>
                  <h3 className="subsection__title">{displayTerm || term}</h3>
                  <div>{definition}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default GlossarySection;
