import React, { FC } from 'react';
import { OptionalChildrenProps } from '../../config';
import { StateProvider } from './StateProvider';
import { Chapters, VideoReducer } from './video-player-types';
import { VideoPlayerLayout } from './VideoPlayerLayout';

const reducer: VideoReducer = (state, change) => {
  if (change.type === 'updateCurrents' && typeof change.payload === 'object') {
    return {
      ...state,
      ...change.payload,
    };
  }

  return {
    ...state,
    [change.type]: change.payload,
  };
};

export type VideoPlayerProps = OptionalChildrenProps & {
  chapters: Chapters;
  poster?: string;
  small?: boolean;
};

/**
 * Main chapterized video player organism
 */
export const VideoPlayer: FC<VideoPlayerProps> = ({
  children,
  poster,
  chapters,
  small,
}) => {
  return (
    <StateProvider
      reducer={reducer}
      initialState={{
        chapters,
        currentChapter: chapters[0],
      }}
    >
      <VideoPlayerLayout poster={poster} small={small}>
        {children}
      </VideoPlayerLayout>
    </StateProvider>
  );
};
